<template>
  <div class="api-client-list-item" @click.prevent="$emit('selected')">
    <div class="left">
      <api-client-thumbnail
        :tenant-id="tenantId"
        :api-client="apiClient"
      ></api-client-thumbnail>
    </div>
    <div class="right">
      <div class="title">
        {{ apiClient.displayName }}
      </div>
      <div class="description">
        {{ apiClient.headline }}
      </div>
    </div>
    <div class="extra-right" v-if="granted">
      <a-badge
        count="Granted"
        :number-style="{ backgroundColor: '#52c41a' }"
      ></a-badge>
    </div>
  </div>
</template>

<script>
import ApiClientThumbnail from "../../../Incidents/Show/Tabs/Escalate/ShareWith/ApiClientCard/ApiClientThumbnail.vue";
export default {
  props: ["tenantId", "apiClient", "granted"],
  components: { ApiClientThumbnail },
};
</script>

<style scoped lang="scss">
.api-client-list-item {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #fff;
  margin-bottom: 15px;

  display: flex;
  .left {
    flex-shrink: 1;
    min-width: 50px;

    .anticon {
      font-size: 25px;
      color: #5155ea;
    }

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .right {
    flex-grow: 1;
    padding-left: 15px;
  }

  .extra-right {
    flex-shrink: 1;
    padding-left: 10px;
    text-align: right;
    display: flex;
    align-items: center;
  }

  .description {
    font-weight: 400;
    margin-top: 3px;
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }
}
</style>